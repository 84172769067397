<template>
    <div class="crud-table relative">
        <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
            <Preloader />
        </div>

        <div class="table-wrapper bg-white" :class="{ 'disabled-form': loading }">
            <table class="table">
                <thead>
                    <tr class="border-b-2">
                        <th v-for="header in headers" :key="header.field" class="whitespace-nowrap header-action">
                            <span>{{ header.label }}</span>
                        </th>
                        <th style="width: 46px">
                            <button type="button" class="btn btn-primary" @click="onAddRowClick">
                                <PlusCircleIcon class="stroke-current" />
                            </button>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(row, rowIdx) in modelValue" :key="rowIdx" class="bg-gray-200 dark:bg-dark-1 table-row">
                        <td v-for="(header, headerIdx) in headers" :key="headerIdx" class="border-b dark:border-dark-5">
                            <template v-if="header.field === 'image' || header.type === 'image'">
                                <UploadImage
                                    v-model="modelValue[rowIdx].image"
                                    v-model:delete="modelValue[rowIdx].image_delete"
                                    class="small"
                                    :image="modelValue[rowIdx].imagePreview"
                                ></UploadImage>
                            </template>
                            <template v-else>
                                <input
                                    v-model="modelValue[rowIdx][header.field]"
                                    type="text"
                                    class="form-control"
                                    autocomplete="off"
                                />
                            </template>
                        </td>

                        <td class="border-b dark:border-dark-5">
                            <button
                                type="button"
                                class="btn border-0 shadow-none"
                                @click.prevent="onDeleteRowClick(rowIdx)"
                            >
                                <XCircleIcon />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <Pagination
            v-if="meta && meta.total > meta.per_page"
            :current-page="meta.current_page"
            :last-page="meta.last_page"
        />
    </div>
</template>

<script>
import Preloader from '@/components/preloader/Main';
import Pagination from '@/components/pagination/Main';
import UploadImage from '@/components/upload-image/UploadImage';

export default {
    name: 'TeamsTable',
    components: { UploadImage, Pagination, Preloader },
    props: {
        loading: {
            type: Boolean,
        },
        headers: {
            type: Array,
            required: true,
        },
        modelValue: {
            type: Array,
            required: true,
        },
        meta: {
            type: Object,
        },
    },
    data() {
        return {
            initItem: null,
        };
    },
    beforeMount() {
        this.fillInitItem();
    },
    methods: {
        fillInitItem() {
            let res = {};
            for (const key in this.modelValue[0]) {
                res[key] = null;
            }
            this.initItem = res;
        },
        onDeleteRowClick(idx) {
            this.modelValue.splice(idx, 1); // удаляем напрямую без эмита
        },
        onAddRowClick() {
            const copy = JSON.parse(JSON.stringify(this.initItem));
            this.$emit('update:modelValue', [...this.modelValue, copy]);
        },
    },
};
</script>

<style scoped lang="scss">
.table-row {
    transition: 0.3s;
    background-color: #fff;

    &:nth-child(odd) {
        background-color: #fafafa;
    }

    &:hover {
        background-color: #eee;
    }
}

.action-btn {
    display: flex;
    width: 30px;
    height: 30px;
    padding: 5px;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 3px;

    &.hidden {
        display: none;
    }

    &:last-child {
        margin-right: 0;
    }
}
</style>
