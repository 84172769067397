export const pageCategoriesMixin = {
    data() {
        return {
            loading: false,
            form: {
                name: null,
                slug: null,
                seo_block_title: null,
                seo_block_description: null,
            },
            seo: this.getDefaultSeo(),
            seoLabels: {
                title: 'SEO заголовок',
                keywords: 'SEO ключевые слова',
                description: 'SEO описание',
            },
            seoHeaders: [
                { field: 'code', label: 'Название параметра' },
                { field: 'value', label: 'Значение' },
            ],
        };
    },
    methods: {
        getDefaultSeo() {
            return [
                { code: 'title', value: '' },
                { code: 'keywords', value: '' },
                { code: 'description', value: '' },
                { code: 'query', value: '' },
            ];
        },
        clearForm() {
            for (const key in this.form) {
                this.form[key] = null;
            }
        },
        async fetchCategory() {
            this.loading = true;
            try {
                const { data } = await this.axios.get(`/page-categories/${this.$route.params.id}/edit`);

                for (const field in this.form) {
                    this.form[field] = data.data[field];
                }

                this.seo = data.data.seo ?? this.seo;

                this.loading = false;
            } catch (ex) {
                console.log('cant fetch category: ' + ex);
                this.loading = false;
                this.$router.push('/error-page');
            }
        },
    },
};
